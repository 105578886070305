import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

    Chart.register(ChartDataLabels);

    function setupChart(id, labels, datasets) {
        let ctx = document.querySelector('#barChart_' + id).getContext('2d');
        
        let chartData = {
            labels: labels,
            datasets: datasets
        };
        
        let barChart = new Chart(ctx, {
            type: 'bar',
            data: chartData,
            options: {
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            font: {
                                family: "eniexpregregular, 'Source Sans Pro', sans-serif",
                                size: 18
                            },
                        },
                        stacked: true,
                    },
                    y: {
                        beginAtZero: true,
                        stacked: true,
                        grid: {
                            tickWidth: 1
                        },
                        ticks: {
                            font: {
                                family: "eniexpregregular, 'Source Sans Pro', sans-serif",
                                size: 18
                            }
                        }
                    },
                },
                responsive: true,
                interaction: {
                    intersect: false,
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            font: {
                                family: "eniexpregregular, 'Source Sans Pro', sans-serif",
                                size: 18
                            },
                            padding: 24,
                        },
                        title: {
                            padding: 24
                        }
                    },
                    title: {
                        display: false,
                    },
                    datalabels: {
                        color: "#ffffff",
                        display: true,
                        font: {
                            family: "eniexpregregular, 'Source Sans Pro', sans-serif",
                            size: 16
                        },
                        formatter: function(value, ctx) {
                            return value + "";
                        },
                    },
                    
                    tooltip: {
                        filter: function (tooltipItem) {
                            if(tooltipItem.raw != 0) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        },
                        animation: {
                            duration: 0
                        },
                        titleFont : {
                            family: "eniexpregregular, 'Source Sans Pro', sans-serif",
                            size: 16
                        },
                        bodyFont: {
                            family: "eniexpregregular, 'Source Sans Pro', sans-serif",
                            size: 16
                        },
                        callbacks: {
                            title: function(context) {
                                if(context[0] && context[0].raw != '') {
                                    return context[0].dataset.label;
                                }
                                else {
                                    return null;
                                }
                            },
                            label: function(context) {
                                if(context.raw != '') {
                                    if(context.label) return context.label + ': ' + context.formattedValue + "";
                                    return context.formattedValue + "";                                
                                }
                                else {
                                    return null;
                                }
                            }
                        },
                    },
                }
            },
            plugins: [{
                afterDraw: function(chart) { 
                   const ctx = chart.ctx;
                   const xAxis = chart.scales.x;
                   ctx.save();
            
                    let ticks = chart.scales.x.ticks;
                    ticks.forEach(function(tick) {
                        if(tick.label == '') {
                            let xValue = xAxis.getPixelForValue(tick.value);

                            ctx.beginPath();
                            ctx.strokeStyle = 'rgb(255, 255, 255)';
                            ctx.lineWidth = 1;
                            ctx.moveTo(xValue, chart.scales.y.getPixelForValue(0));
                            ctx.lineTo(xValue + 12, chart.scales.y.getPixelForValue(0));
                            ctx.stroke();

                            ctx.beginPath();
                            ctx.strokeStyle = 'rgba(0, 0, 0, 0.4)';
                            ctx.lineWidth = 1;
                            ctx.moveTo(xValue + 6, chart.scales.y.getPixelForValue(0) - 16);
                            ctx.lineTo(xValue - 6, chart.scales.y.getPixelForValue(0) + 16);
                            ctx.moveTo(xValue + 18, chart.scales.y.getPixelForValue(0) - 16);
                            ctx.lineTo(xValue + 6, chart.scales.y.getPixelForValue(0) + 16); 
                            ctx.stroke();

                        }
                    });
                }
            }],
        });
        if(window.innerWidth <= 600) {
            barChart.options.maintainAspectRatio = false;
            barChart.update();
        }
    }

window.setupChart = setupChart;
import { loadTarget } from "./_lazy-image";
const newsArchive = document.querySelector('body.page-template-page-news-archive');

if(newsArchive) {
    const searchButton = newsArchive.querySelector('.news-search-wrapper input[type="submit"]');
    const searchInput = newsArchive.querySelector('.news-search-wrapper input#news-search');
    let container = newsArchive.querySelector('.news-wrapper');
    let deleteBtn = newsArchive.querySelector('.reset-search');

    if(searchInput.value != '') {
        deleteBtn.classList.add('show');
    }

    searchInput.addEventListener('keyup', function (e) {
        let searchText = e.target.value.toLowerCase();
        if(searchText != '') {
            deleteBtn.classList.add('show');
        }
        else {
            deleteBtn.classList.remove('show');
            window.history.replaceState({}, '', '?search=');
            getPosts(searchInput.value, container.getAttribute('data-lang'));
        }
    });
    deleteBtn.addEventListener('click', function(e) {
        searchInput.value = '';
        deleteBtn.classList.remove('show');
        window.history.replaceState({}, '', '?search=');
        getPosts(searchInput.value, container.getAttribute('data-lang'));
    });

    searchButton.addEventListener('click', function(e) {
        e.preventDefault();
        window.history.replaceState({}, '', '?search=' + searchInput.value);
        getPosts(searchInput.value, container.getAttribute('data-lang'));
    });

    function getPosts(search, language) {
        let data = new FormData();
        data.append('action', 'get_posts');
        data.append('search', search);
        data.append('language', language);

        $.ajax({
            url: varenergi_scripts.ajax,
            type: 'POST',
            data: data,
            cache: false,
            processData: false,
            contentType: false,
            error: function (data) {
                console.error(data);
            },
            complete: function (data) {
                container.innerHTML = data.responseText;

                if ('IntersectionObserver' in window) {
                    let options = {
                        root: null, // relative to document viewport
                        rootMargin: '0px 0px 400px', // margin around root. Values are similar to css property. Unitless values not allowed
                        threshold: 0 // visible amount of item shown in relation to root
                    };
                
                    let onIntersectionChange = function (changes, observer) {
                        changes.forEach(change => {
                            if (change.intersectionRatio > 0) {
                                loadTarget(change.target);
                            }
                        });
                    };
                    let observer = new IntersectionObserver(onIntersectionChange, options);
                    let images = document.querySelectorAll('img.lazy');
                    images.forEach(img => observer.observe(img));
                }
                else {
                    let lazyImages = document.getElementsByClassName('lazy');
                    for (var i = 0; i < lazyImages.length; i++) {
                        var img = lazyImages[i];
                        loadTarget(img);
                    }
                }
            }
        });
    }
}
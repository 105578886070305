const h1 = document.querySelectorAll('h1');
const h2 = document.querySelectorAll('h2');
const h3 = document.querySelectorAll('h3');
const headingTags = [h1, h2, h3];
const shortcutsContainer = document.querySelector('section.link-shortcuts .wrapper.all-headings');
let shortcuts = [];
let html = '<a href="#to-top" class="back-to-top" aria-label="To top of page"><svg viewBox="0 0 35.29 68.15"><defs><style>.d{fill:none;stroke:#1D1D1D;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4px;}</style></defs><g id="a"/><g id="b"><g id="c"><g><line class="d" x1="17.19" y1="2.5" x2="17.19" y2="64.06"/><path class="d" d="M2.5,51.2l13.95,13.94c.69,.69,1.8,.69,2.49,0l13.85-13.98"/></g></g></g></svg></a>';

if(shortcutsContainer) {
    headingTags.forEach((tag) => {
        tag.forEach((heading) => {
            const title = heading.textContent;
            if (title && heading.getAttribute('id') && heading.getAttribute('id').length > 0) {
                const id = heading.getAttribute('id');
                shortcuts.push({ id, title });
                html += '<a href="#' + id + '">' + title + '</a>';
            }
        });
    });
    shortcutsContainer.innerHTML = html;

    if(document.querySelector('section.link-shortcuts a.back-to-top')) {
        document.querySelector('section.link-shortcuts a.back-to-top').addEventListener('click', function(e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: 0
            }, 400);
        });
    }
}

if(document.querySelector('section.link-shortcuts a.back-to-top')) {
    document.querySelector('section.link-shortcuts a.back-to-top').addEventListener('click', function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 400);
    });
}
const { B } = require('core-js/modules/_export');

var menuFirstTime = true;

$(document).ready(function () {
  $('.toggle').click(function () {
    if ($(this).find('#nav-icon').hasClass('open')) {
      closeNav();
    } else {
      openNav();
    }
  });

  $('.searchicon').click(function () {
    openNav();
    $('#search').focus();
  });
    $('.websearch').click(function () {
      if ($(this).hasClass('open') && $('#desktop-menu.open').length > 0) {
        $('#desktop-menu').css('top', $('.site-top').offset().top - $(window).scrollTop() + $('.site-top').height() - $('.websearchwrapper').height() - 16 + 'px');
      }
      else if($('#desktop-menu.open').length > 0) {
          $('#desktop-menu').css('top', $('.site-top').offset().top - $(window).scrollTop() + $('.site-top').height() + 46 + $('.websearchwrapper').height() + 'px');
      }
    });
});

function openNav() {
  $('#myNav').css('height', '100%');

  fixPosition();
  if (menuFirstTime) openMenuFirstTime();
  $('body').css('overflow-y', 'hidden');
  $('#myNav').css('overflow-y', 'scroll');

  $('#nav-icon').addClass('open');
  $('#desktop-menu').addClass('open');
  setTimeout(function () {
    $('#desktop-menu').css('overflow-y', 'scroll');
    $('#desktop-menu').css('height', '100%');
  }, 100);
}

function closeNav() {
  $('#myNav').css('height', '0');
  $('#desktop-menu').css('height', '0');
  $('body').css('overflow-y', 'auto');
  $('#nav-icon').removeClass('open');

  setTimeout(function () {
    $('#desktop-menu').removeClass('open');
  }, 500);
}

function fixPosition() {
  var siteTop = $('.site-top');

  var position;
  if ($(window).width() < 600) {
    position = siteTop.offset().top - $(window).scrollTop() + siteTop.height();
  } else {
    position = siteTop.offset().top - $(window).scrollTop() + siteTop.height() + 16;
  }
  $('.mobile-menu-overlay').css('top', position + 'px');
  $('#desktop-menu').css('top', position + 'px');
}

function openMenuFirstTime() {
  var current = $('a.current-menu-item');
  if (current.length > 1) {
    current = $("a[href*='" + window.location.href + "']").first();
  }

  if (!current.is('.menu')) {
    current = current.parent().prev();
  }
  while (current.is('.menu')) {
    openMenu(current, false);
    current = current.parent().prev();
  }
  menuFirstTime = false;
}

$('a.menu').click(function () {
  var $this = $(this);
  openMenu($this, true);
});

function openMenu($this, animate) {
  var subMenu = $this.next();
  var duration = animate ? 300 : 0;

  animateArrow($this.find('.arrow').get(0), subMenu.is(':visible'), duration);
  if (subMenu.is(':visible')) {
    subMenu.hide(duration);
  } else {
    var menus = subMenu.parent().find('.sub-menu');
    menus.each(function () {
      if ($(this).is(':visible')) {
        animateArrow($(this).prev().find('.arrow').get(0), true, duration);
        $(this).hide(duration);
      }
    });
    subMenu.show(duration);
  }
  fixPosition();
}

function animateArrow(arrow, visible, duration) {
  anime({
    targets: arrow,
    rotate: { value: visible ? 45 : -135, duration: duration },
    easing: 'linear',
    complete: function (anim) {
      fixPosition();
    },
  });
}

function getMenuItemId(item) {
  var classList = item.attr('class').split(/\s+/);
  var searchPattern = new RegExp('^menu-item-\\d+');
  for (var pos = 0; pos < classList.length; pos++) {
    var className = classList[pos];
    if (searchPattern.test(className)) {
      return className.split('-', 3)[2];
    }
  }
}

function fixDuplicateMenus() {
  var menus = $('a.menu-item');
  var menuMap = {};
  var menuExist = {};
  menus.each(function () {
    var menu = $(this);
    var url = menu.get(0).href;
    if (url in menuExist) {
      if (url in menuMap) {
        menuMap[url].push(menu);
      } else {
        menuMap[url] = [menuExist[url], menu];
      }
    } else {
      menuExist[url] = menu;
    }
  });

  for (var url in menuMap) {
    for (var pos = 0; pos < menuMap[url].length; pos++) {
      var item = menuMap[url][pos];
      var id = getMenuItemId(item);
      item.get(0).href = item.get(0).href + '#' + id;
    }
  }
}

fixDuplicateMenus();

//Focus tabbing

$('.toggle').focus(function () {
  $(this).addClass('active');
});

$('.toggle').blur(function () {
  $(this).removeClass('active');
});

$('.site-menu li:last-of-type a').blur(function () {
  setTimeout(function () {
    if ($('.site-menu li:last-of-type').prev().children('a').is(':focus')) {
      //$('#desktop-menu').add('open');
    } else {
      $('#myNav').css('height', '0');
      $('#desktop-menu').css('height', '0');
      $('body').css('overflow-y', 'auto');
      $('#nav-icon').removeClass('open');

      setTimeout(function () {
        $('#desktop-menu').removeClass('open');
      }, 500);
    }
  }, 100);
});

let accordions = document.querySelectorAll(".accordion .item h3");

if(accordions) {
    accordions.forEach(function(item, index) {
        let parent = item.parentNode;
        item.addEventListener("click", function () {
            if (parent.classList.contains('active')) {
                parent.classList.remove('active');
            } else {
                parent.classList.add('active');
            }
        });
        item.addEventListener("keydown", function(event) {
            if (event.keyCode == 13) {
                if (parent.classList.contains('active')) {
                    parent.classList.remove('active');
                } else {
                    parent.classList.add('active');
                }
            }
          });
    });
}
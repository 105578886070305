const block = document.querySelector('section.reports-and-presentation');

if(block) {
    let yearSelect = block.querySelector('table select');
    let tables = block.querySelectorAll('table tbody');
    yearSelect.addEventListener('change', function() {
        yearSelect.querySelector('option.selected').classList.remove('selected');
        yearSelect.querySelectorAll('option').forEach((item) => {
            if(item.value == yearSelect.value) {
                item.classList.add('selected');
            }
        });
        if(block.querySelector('tbody.current')) {
            block.querySelector('tbody.current').classList.remove('current');
        }
        tables.forEach((table) => {
            if(table.getAttribute('data-year') == yearSelect.value) {
                table.classList.add('current');
            }
        });
    });
}
if ($('body').hasClass('page-template-page-two-thoughts-in-mind')) {
    setTimeout(function() {
        var $videos = $('.two-thoughts-in-mind video');
        var $window = $(window);

        if ("IntersectionObserver" in window) {
            var videoObserver = new IntersectionObserver(function(entries, observer) {
                for (var i = 0; i < entries.length; i++) {
                    var video = entries[i];
                    var $video = $(video.target);
                
                    if (!$video.hasClass('playing') && video.isIntersecting) {
                        video.target.play();
                        $video.addClass('playing');
                    } else if ($video.hasClass('playing') && !video.isIntersecting) {
                        video.target.pause();
                        video.target.currentTime = 0;
                        $video.removeClass('playing');
                    }

                }
            });

            $.each($videos, function(i, video) {
                videoObserver.observe(video);
            })
        } else {
            $.each(videos, function(i, video) {
                video.play();
            })
        }
    }, 500);
}
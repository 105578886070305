const pressReleasesArchive = document.querySelector('body.page-template-page-press-releases .section__press_releases');

if(pressReleasesArchive) {
    const searchButton = document.querySelector('.press-releases-search-wrapper input[type="submit"]');
    const searchInput = document.querySelector('.press-releases-search-wrapper input#press-releases-search');
    let container = document.querySelector('.section__press_releases');
    let deleteBtn = document.querySelector('.press-releases-search-wrapper .reset-search');

    if(searchInput.value != '') {
        deleteBtn.classList.add('show');
    }

    searchInput.addEventListener('keyup', function (e) {
        let searchText = e.target.value.toLowerCase();
        if(searchText != '') {
            deleteBtn.classList.add('show');
        }
        else {
            deleteBtn.classList.remove('show');
            window.history.replaceState({}, '', '?search=');
            getPosts(searchInput.value, container.getAttribute('data-lang'));
        }
    });
    deleteBtn.addEventListener('click', function(e) {
        searchInput.value = '';
        deleteBtn.classList.remove('show');
        window.history.replaceState({}, '', '?search=');
        getPosts(searchInput.value, container.getAttribute('data-lang'));
    });

    searchButton.addEventListener('click', function(e) {
        e.preventDefault();
        window.history.replaceState({}, '', '?search=' + searchInput.value);
        getPosts(searchInput.value, container.getAttribute('data-lang'));
    });

    function getPosts(search, language) {
        let data = new FormData();
        data.append('action', 'get_press_releases');
        data.append('search', search);
        data.append('language', language);

        $.ajax({
            url: varenergi_scripts.ajax,
            type: 'POST',
            data: data,
            cache: false,
            processData: false,
            contentType: false,
            error: function (data) {
                console.error(data);
            },
            complete: function (data) {
                container.innerHTML = data.responseText;
            }
        });
    }
}
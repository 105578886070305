const publicationBlocks = document.querySelectorAll('section.page-content .publications.with-disclaimer');

if(publicationBlocks) {
    publicationBlocks.forEach(function(block, index) {
        let links = block.querySelectorAll('.row a');
        let downloadBtn = block.querySelector('a.file-download');
        let closeLink = block.querySelector('.disclaimer-modal a.close');
        let modalClose = block.querySelector('.disclaimer-modal button.modal-close');
        let checkbox = block.querySelector('.accept-container');

        links.forEach(function(link, index) {
            link.addEventListener('click', function(e) {
                e.preventDefault();
                block.querySelector('.overlay').classList.add('show');
                block.querySelector('.disclaimer-modal').classList.add('show');
                document.querySelector('body').classList.add('no-scroll');
                block.querySelector('a.file-download').setAttribute('href', link.getAttribute('href'));
            });
        });
        downloadBtn.addEventListener('click', function() {
            setTimeout(function() {
                closeModal(block);
            }, 100);
        });
        closeLink.addEventListener('click', function(e) {
            e.preventDefault();
            closeModal(block);
        });
        modalClose.addEventListener('click', function(e) {
            e.preventDefault();
            closeModal(block);
        });
        checkbox.addEventListener('click', function() {
            if(checkbox.querySelector('input').checked == true) {
                downloadBtn.classList.add('show');
            }
            else {
                downloadBtn.classList.remove('show');
            }
        });
    });

    function closeModal(block) {
        block.querySelector('.overlay').classList.remove('show');
        block.querySelector('.disclaimer-modal').classList.remove('show');
        block.querySelector('a.file-download').setAttribute('href', '');
        block.querySelector('a.file-download').classList.remove('show');
        block.querySelector('.accept-container').querySelector('input').checked = false;
        document.querySelector('body').classList.remove('no-scroll');
    }
}
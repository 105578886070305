let calendars = document.querySelectorAll('section.financial-calendar');

if(calendars) {
    calendars.forEach(function(calendar, index) {
        let styles = {
            margin: '0',
            color: '#1D1D1D',
            padding: '0.125rem 0',
            borderRadius: '0',
            backgroundSize: '100% 8%',
            backgroundImage: 'linear-gradient(120deg,#84C561 0,#84C561 100%)',
            fontFamily: 'eniexpregregular, Source Sans Pro, sans-serif'
        };
        let events = calendar.querySelectorAll('.atcb-light');
        events.forEach(function(event, index){
            let shadowDom = event.shadowRoot;
            let button = shadowDom.querySelector('.atcb-button');
            Object.assign(button.style, styles);
            shadowDom.querySelector('.atcb-button-wrapper').style.padding = '0';
        });
    
        let yearSelect = calendar.querySelector('.events-archive select');
        let separator = calendar.querySelector('hr.separator');
        if(yearSelect) {
            let prevEvents = calendar.querySelectorAll('.events-archive .row');
            yearSelect.addEventListener('change', function() {
                if(yearSelect.querySelector('option.selected')) {
                    yearSelect.querySelector('option.selected').classList.remove('selected');
                }
                yearSelect.querySelectorAll('option').forEach((item) => {
                    if(item.value == yearSelect.value) {
                        item.classList.add('selected');
                    }
                });
                separator.classList.add('hide');
                prevEvents.forEach((event) => {
                    event.classList.remove('active');
                    if(event.getAttribute('data-year') == yearSelect.value) {
                        event.classList.add('active');
                        separator.classList.remove('hide');
                    }
                });
            });
        }
    });
}
